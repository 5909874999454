<template>
    <Dialog :header="dialogLabels.header" v-model:visible="display" :modal="true" class='create-course-modal'
        :draggable='false' @show='onShown'>

        <div class='course-name-input-container'>
            <div :class="{ 'p-float-label': true, 'input-error': v$.name.$error }">
                <InputText id='nameInput' type="text" v-model='v$.name.$model' @input="() => resetError('name')"
                    :disabled="course && course.courseId == 0" />
                <label for='nameInput' class='input-label'>Course name</label>
            </div>
            <template v-for="error of v$.name.$errors" :key="error.$uid">
                <span class='error-message'>{{ error.$message }}</span>
            </template>
        </div>
          

        <div class='course-desc-textarea-container'>
            <span :class="{ 'p-float-label': true, 'input-error': v$.description.$error }">
                <Textarea id='descriptionTextarea' rows="5" cols="30" v-model='v$.description.$model' />
                <label for='descriptionTextarea' class='input-label'>Course description</label>
                <span class='course-desc-counter' :style="{ color: v$.description.$error ? '#E63E3E' : null }">{{ (description
                    ? description.length : 0) }} / {{ textLimits.courseDescLimit }} </span>
            </span>
        </div>
        <div style="width:100%;display:flex;align-content:center;align-items:center;">
                <div class="column">
                    <span>Icon:</span>
                    <div class="info-icon-container">
                        <i class="pi pi-info-circle" 
                            v-tooltip.bottom="`The icon shown in the course listing.(60 x 60 pixels best)`"></i>
                    </div>
                </div>
                <div class=' avatar-container' style="display:flex;flex-direction:row;margin-left:auto;">
                    <!-- <div class='rounded-square'> -->
                        <div class="p-avatar p-component p-avatar-image lg rounded-square" style="min-width:60px;max-width:60px;" v-show="imageUrl" >
                            <img id='courseImage' :src='imageUrl' />
                        </div>
                        
                        
                        <span  v-show="!imageUrl"> No Image</span>
                    <!-- </div> -->
                    <div style="margin-left:5px;">
                        <Button icon="pi pi-trash" v-show='imageUrl' @click='removeImportedImage' style="margin-right:5px;" v-tooltip.bottom="`Remove current image.`"/>
                        <Button icon="pi pi-search"  @click='openFileDialogForImportingImage' v-tooltip.bottom="`Choose Image.`"/>
                        <!-- <span class='p-button p-component action-button' icon="pi pi-search" @mouseup='openFileDialogForImportingImage'> -->
                            
                            <ImageFileInput srcImageId='courseImage' @selectedImage='selectedImage($event)' @openErrorModal='openErrorModal($event)'/>
                        <!-- </span> -->
                    
                        
                    </div>
                </div>
            </div>
        <div style="width:100%;display:flex;align-content:center;align-items:center;">
           
            <label for="admisionOpen" class='input-label'>Admissions Open:</label>
             <div class="info-icon-container">
                <i class="pi pi-info-circle" 
                    v-tooltip.bottom="`When enabled users can register for the course.  Admission can only be enabled when the course has at least one cohort.  You create cohorts after the course has been created. `"></i>
            </div>
            <InputSwitch id="admisionOpen" class="input-switch" v-model="admissionOpen" style="margin-left:auto;" :disabled="!course?.hasCohorts"/>
        </div>
        <div style="width:100%;display:flex;align-content:center;align-items:center;margin-top:5px;">
            <label for="admisionOpen" class='input-label'>Course Code:</label>
            <div class="info-icon-container">
                <i class="pi pi-info-circle" 
                    v-tooltip.bottom="`A unique alpha numeric code for your course.  Used in various areas in app where course name may be too long.  Required (max 10 chars). `"></i>
            </div>
            <InputText id="courseCode" class="input-text" v-model="courseCode" style="margin-left:auto;"/>
        </div>
        <div v-if="!selectedGroup.systemGroup " style="width:100%;display:flex;align-content:center;align-items:center;margin-top:5px;">
            <label for="membershipDuration" class='input-label'>Price:</label>
            <div class="info-icon-container" style="margin-right:auto;">
                <i class="pi pi-info-circle" 
                    v-tooltip.bottom="`Users will have to pay to register. This option is only available if you are the group owner and a have connected a Stripe Account in your Profile Payments section. Optional. `"></i>
            </div>
            
            <InputNumber id='productPrice'  v-model="productPrice" mode="currency" :currency="productCurrency.name" :disabled="!isGroupOwner() || sellerAccountStatus != 'accountEnabled'"/>
            <Dropdown id="productCurrency" class="currency-type-dropdown"  style="margin-left:5px;" v-model="productCurrency" :options="currencyTypeOptions" optionLabel='name' 
                placeholder="Select Currency" :disabled="!isGroupOwner() || sellerAccountStatus != 'accountEnabled'"/>
        </div>
         <div v-if="selectedGroup.systemGroup" style="width:100%;display:flex;align-content:baseline;align-items:baseline;margin-top:5px;" >
                <label for="pass" class='input-label' style="margin-right:auto;">Investor Pass:</label>
                <Dropdown id="pass" class="pass-dropdown" v-model="pass" :options="passOptions" optionLabel='name' />
                
            
        </div>
        <Accordion style="margin-top:5px;" >
            <AccordionTab >
           <template #header>
            
            <div style="width:100%;display:flex;align-content:center;align-items:center;">
                 <span class="font-bold">Include Investment Account?</span>
                <div class="info-icon-container">
                    <i class="pi pi-info-circle" 
                        v-tooltip.bottom="`When enabled, a pre-funded educational investment account is issued to all students upon registration.  Optional. `"></i>
                </div>
                    <InputSwitch id="includeInvAcct" class="input-switch" v-model="includeInvAcct" style="margin-left:auto;"/>
            </div>
            </template>

            
            <div v-show="includeInvAcct">
                <div style="width:100%;display:flex;align-content:baseline;align-items:baseline;margin-top:5px;">
                        <label for="currencyType" class='input-label' >Account Currency:</label>
                        <div class="info-icon-container" style="margin-right:auto;">
                            <i class="pi pi-info-circle" 
                                v-tooltip.bottom="`The primary currency for the account.`"></i>
                        </div>
                        <Dropdown id="currencyType" class="currency-type-dropdown" v-model="currencyType" :options="currencyTypeOptions" optionLabel='name' placeholder="Select Currency"/>
                        
                    
                </div>
                
                <div style="width:100%;display:flex;align-content:baseline;align-items:baseline;margin-top:5px;">
                        <label for="prefundAmount" class='input-label' >Starting Cash Balance:</label>
                       <div class="info-icon-container" style="margin-right:auto;">
                            <i class="pi pi-info-circle" 
                                v-tooltip.bottom="`The pre-fund amount deposited when the account is created.(Max $1,000,000.00).`"></i>
                        </div>
                        <InputNumber id='prefundAmount' class='avg-cost-input' v-model="prefundAmount" mode="currency" :currency="currencyType.name"  :max="1000000.00"/>
                        
                    
                </div>
               

                
            </div>
            </AccordionTab>
        </Accordion>

        <template #footer>
            <Button class='create-course-button' :label="dialogLabels.footerButton" :disabled='disableSubmitButton'
                :loading='loading' @click='onFooterButtonClicked()' />
        </template>
    </Dialog>
</template>

<script>
import Dialog from 'primevue/dialog';
import InputText from 'primevue/inputtext';
import Textarea from 'primevue/textarea';

import useVuelidate from '@vuelidate/core'
import { maxLength } from '@vuelidate/validators';

import GroupService from '../../../service/GroupService';


import EventBus from '../../../event-bus';
import InputSwitch from 'primevue/inputswitch';

import Accordion from 'primevue/accordion';
import AccordionTab from 'primevue/accordiontab';
import InputNumber from 'primevue/inputnumber';


import { CANADIAN_CURRENCY_TYPE, US_CURRENCY_TYPE} from '../../../common/constants';
import Dropdown from 'primevue/dropdown';
import PaymentService from '../../../service/PaymentService';

import UserUtils from '../../../utilities/UserUtils';

import ImageFileInput from '../../fileInput/ImageFileInput.vue';

import { APP_URL } from '../../../common/config';

const dialogLabelsOptions = {
    create: {
        header: "Create Course",
        footerButton: "Create"
    },
    edit: {
        header: "Edit Course",
        footerButton: "Save"
    }
}

const textLimits = {
    courseNameLimit: 50,
    courseDescLimit: 2000
};

export default {
    name: 'CreateEditCourseModal',
    setup: () => ({ v$: useVuelidate() }),
    data() {
        return {
            userUtils: UserUtils,
            dialogLabels: dialogLabelsOptions.create,
            textLimits,

            display: false,
            editMode: false,
            loading: false,

            course: null,
            selectedGroup: null,

            name: null,
            description: null,
            includeInvAcct: false,
            admissionOpen: false,
            prefundAmount: null,
            currencyType: null,
            benchmarkFundId: null,
            pass: null,
            courseCode: null,
            membershipDuration:null,
            currencyTypeOptions: [{id: CANADIAN_CURRENCY_TYPE['ID'], name: CANADIAN_CURRENCY_TYPE['SYMBOL']}, {id: US_CURRENCY_TYPE['ID'], name: US_CURRENCY_TYPE['SYMBOL']}],
            passOptions: [{id:0, name:'None'}, {id:10, name:'Basic'}, {id:11, name:'Premium'}],

            productCurrency: null,
            productPrice: null,
            sellerAccountStatus: 'noAccount',
            imageFile : null,
            imageUrl: APP_URL+'/assets/images/eduAccount-small.png',
        }
    },
    components: {
        Dialog, InputText, Textarea,
        InputSwitch,AccordionTab, Accordion,InputNumber,
        Dropdown,
        ImageFileInput,
    },

    computed: {
        disableSubmitButton() {
            const isNameAndDescEmpty = !this.name && !this.description
            return this.v$.$errors.length > 0 || isNameAndDescEmpty ? 'disabled' : null;
        }
    },

    methods: {

        openFileDialogForImportingImage() {
            EventBus.emit('open-file-dialog');
        },
        selectedImage(importedImage) {
            this.imageFile = importedImage;
            this.imageUrl = importedImage.url;
        },
        openErrorModal(errorMessage) {
            this.$refs.importImageErrorModal.open(errorMessage)
        },

         removeImportedImage() {
            document.getElementById('courseImage').src = APP_URL+'/assets/images/eduAccount-small.png'; // Prevents former image from rendering when uploading new image
            this.imageFile = null;
            this.imageUrl = APP_URL+'/assets/images/eduAccount-small.png';
        },

         isGroupOwner() {
            return this.userUtils.isUser(this.selectedGroup.groupOwnerId);
        },
        resetError(field) {
            this.v$[field].$reset();
        },

        onShown() {
            const dialogElement = document.getElementsByClassName('create-course-modal')[0];
            dialogElement.parentElement.style.position = 'absolute';
        },

        open(selectedGroup, course) {
            this.selectedGroup = selectedGroup;
            this.v$.$reset();
            this.display = true;
            this.name = '';
            this.description = '';
            this.sellerAccountStatus = 'noAccount';
             this.imageFile = null;
            this.imageUrl = APP_URL+'/assets/images/eduAccount-small.png';
            PaymentService.getSellerAccountStatus().then(resp => {
               
                if( resp.data.status == 'success'){
                    this.sellerAccountStatus = resp.data.sellerAccountStatus;
                }
             });

            if (course) {
                console.log(course);
                this.dialogLabels = dialogLabelsOptions.edit;
                this.editMode = true;
                this.course = course;
                this.initCourseInfo();
                this.v$.$touch();
            } else {
                this.dialogLabels = dialogLabelsOptions.create;
                this.editMode = false;
                this.course = null;
                this.name = null;
                this.description = null;
                this.admissionOpen = false;
                this.includeInvAcct = false;
                this.prefundAmount = null;
                this.currencyType = this.currencyTypeOptions[1];
                
                this.benchmarkFundId = null;
                this.pass = this.passOptions[0];
            
                this.courseCode = null;
                this.membershipDuration = null;

                this.productCurrency = this.currencyTypeOptions[1];
                this.productPrice= null;
            }
        },

        initCourseInfo() {
            this.name = this.course.name;
            this.description = this.course.description;
            this.admissionOpen = this.course.open;
            this.includeInvAcct = this.course.includeInvAcct;
            this.prefundAmount = this.course.preFundAmount;
            if( this.course.invAcctCurrencyId) {
                this.currencyType = (this.course.invAcctCurrencyId == CANADIAN_CURRENCY_TYPE['ID']? this.currencyTypeOptions[0] : this.currencyTypeOptions[1]);
            }
            else {
                this.currencyType = this.currencyTypeOptions[1];
            }
            this.benchmarkFundId = this.course.benchmarkFundId;
            if( this.course.passId) {
                this.pass = (this.course.passId == 10 ? this.passOptions[1] : this.passOptions[2]);
            }
            else {
                this.pass = this.passOptions[0];
            }
            this.courseCode = this.course.courseCode;
            this.membershipDuration = this.course.membershipDurationDays;
            if( this.course.iconUrl) {
                this.imageUrl = this.course.iconUrl;
            }
            if( this.course.courseProduct){
                this.productCurrency = (this.course.courseProduct.currencyId == CANADIAN_CURRENCY_TYPE['ID']? this.currencyTypeOptions[0] : this.currencyTypeOptions[1]);
                this.productPrice= this.course.courseProduct.price;
            }
            else {
                this.productCurrency = this.currencyTypeOptions[1];
                this.productPrice = null;
            }
            console.log("initCourseInfo", this.course, this.name);
        },

        onFooterButtonClicked() {
            this.v$.name.$touch();

            const onErrorResponse = (resp) => {
                if (resp.data.message == 'Invalid Name') {
                    this.$toast.add({ severity: 'error', summary: "Invalid course name.", life: 3500, group: 'center' });
                } else {
                    console.error(resp.data.message);
                    this.$toast.add({ severity: 'error', summary: "An unexpected error occurred.  Please try again or click on 'Support' under your profile pic.", life: 3500, group: 'center' });
                }
            }
            if( !this.courseCode || this.courseCode.trim() == '') {
                this.courseCode = null;
                this.$toast.add({ severity: 'error', summary: "Course code is required.", life: 3500, group: 'center' });
                return;
            }
            if( !this.description || this.description.trim() == '') {
                this.description = null;
                this.$toast.add({ severity: 'error', summary: "Course description is required.", life: 3500, group: 'center' });
                return;
            }

            if (this.v$.name.$errors.length == 0 && this.selectedGroup) {
                this.loading = true;
                let req = {
                    name: this.name,
                    description: this.description,
                    courseCode: this.courseCode,
                    open: this.admissionOpen,
                    includeInvAcc: this.includeInvAcct,
                    prefundAmt: this.prefundAmount,
                    currencyId: this.currencyType?.id,
                    membershipDuration: this.membershipDuration,
                    passId: (this.pass && this.pass.id > 0 ? this.pass.id : null),
                    productCurrency: this.productCurrency?.id,
                    productPrice: this.productPrice,
                    icon: null,
                }

                if( this.imageFile ){
                    req.icon = this.imageFile;
                }
                else if( this.imageUrl ){
                    req.icon = this.createIconFromUrl(this.imageUrl);
                }


                if (!this.editMode) {
                    GroupService.createGroupCourse(this.selectedGroup.groupId, req).then((resp) => {
                        this.loading = false;

                        if (resp.data.status === 'success') {
                            this.display = false;

                            const summaryString = `Created course: ${this.name}`;
                            this.$toast.add({ severity: 'success', summary: `${summaryString} successful!`, life: 2500, group: 'center' });

                            EventBus.emit('refresh-courses-list');
                        } else {
                            onErrorResponse(resp);
                        }
                    });
                } else {
                    GroupService.updateGroupCourse(this.selectedGroup.groupId, this.course.courseId, req).then((resp) => {
                        this.loading = false;

                        if (resp.data.status === 'success') {
                            this.display = false;

                            const summaryString = `Updated course: ${this.name}`;
                            this.$toast.add({ severity: 'success', summary: `${summaryString} successful!`, life: 2500, group: 'center' });

                            EventBus.emit("updated-group-course-actions", resp.data.groupcourse);
                            EventBus.emit('refresh-courses-list');
                        } else {
                            onErrorResponse(resp);
                        }
                    });
                }
            }

        },
        createIconFromUrl(url){
            let name = url.substring(url.lastIndexOf('/')+1);
            if( name.indexOf('?') > 0 ) { //trim off any ? from name
                name = name.substring(0, name.indexOf('?'));
            }
             if( name ) {
                if( name.length > 15){
                    name = name.substring(0, 14);
                }
                name = name.replaceAll('+', '');
                name = name.replaceAll('/', '');
                name = name.replaceAll('=', '');
            }
            return { name: name, url: url};
                
        },

    },

    
    validations() {
        return {
            name: {
                maxLength: maxLength(textLimits.courseNameLimit)
            },

            description: {
                maxLength: maxLength(textLimits.courseDescLimit)
            }
        }
    }
}
</script>

<style>
.create-course-modal {
    position: static;
    font-family: "Trebuchet MS", "Verdana";
    width: 600px;
    border-radius: 16px;
}

.create-course-modal .p-dialog-header {
    padding: 16px;
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
}

.create-course-modal .p-dialog-header .p-dialog-title {
    font-size: 24px;
    font-weight: bold;
}

/*.create-course-modal .p-dialog-content {
    height: 100%;
    overflow-y: hidden;
    border-top: 1px solid #BFBFBF;
    border-bottom: 1px solid #BFBFBF;
    padding: 24px 16px;
}*/

.create-course-modal .p-dialog-footer {
    text-align: center;
    padding: 16px;
    border-bottom-right-radius: 16px;
    border-bottom-left-radius: 16px;
}
</style>
<style scoped>
.create-course-modal .p-dialog-content .avatar-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 5px;
 }

  .create-course-modal .p-dialog-content .avatar-container .rounded-square {
    display: flex;
    width: 60px;
    height: 60px;
    overflow: hidden;
    border: 3px solid #32364e;
    justify-content: center;
    align-items: center;
    margin-bottom: 15px;
   
    border-radius: 16px;
}

 .create-course-modal .p-dialog-content .avatar-container .profile-picture-button-container {
    display: flex;
    margin-bottom: 30px;
}

.create-course-modal .p-dialog-content .avatar-container .profile-picture-button-container .delete:enabled:focus,
.create-course-modal .p-dialog-content .avatar-container .profile-picture-button-container .delete {
    border-color: #E63E3E;
    margin-right: 10px;
}
 .create-course-modal .p-dialog-content .avatar-container .profile-picture-button-container .delete:hover {
    background: #E63E3E;
    border-color: #E63E3E;
}

.info-icon-container i {
    margin-left: auto;
}

.info-icon-container {
    padding: 0 2px 5px;
    display: flex;
}

.info-icon-container i {
    color: #00C083;
}

::v-deep(.p-accordion-header > a) {
    align-items:baseline;
}

.field-radiobutton {
    margin-bottom: 24px;
    padding: 0px 80px;
    display: flex;
    justify-content: space-around;
}

.field-radiobutton .p-radiobutton {
    margin-right: 4px;
}

/* Shaking animation */
@keyframes shakeError {
    0% {
        transform: translateX(0);
    }

    15% {
        transform: translateX(0.375rem);
    }

    30% {
        transform: translateX(-0.375rem);
    }

    45% {
        transform: translateX(0.375rem);
    }

    60% {
        transform: translateX(-0.375rem);
    }

    75% {
        transform: translateX(0.375rem);
    }

    90% {
        transform: translateX(-0.375rem);
    }

    100% {
        transform: translateX(0);
    }
}

.course-name-input-container {
    display: flex;
    flex-direction: column;
    margin-bottom: 24px;
}

.course-name-input-container .p-inputtext {
    font-size: 16px;
    width: 100%;
    padding: 8px 8px 8px 16px;
    height: 36px;
    color: black;
    background: #F2F4FA;
    border: 2px solid #BFBFBF;
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 2px 1px rgba(0, 0, 0, 0.12), 0px 1px 3px rgba(0, 0, 0, 0.2);
    border-radius: 8px;
}

.course-name-input-container .p-inputtext:enabled:hover {
    border-color: #33CC99;
}

.course-name-input-container .p-inputtext:enabled:focus {
    border-color: #33CC99;
}

.course-name-input-container>label {
    font-size: 16px;
    padding-left: 16px;
    color: #BFBFBF;
}

.course-desc-textarea-container {
    margin-bottom: 8px;
}

.course-desc-textarea-container .p-inputtextarea {
    font-size: 16px;
    resize: none;
    width: 100%;
    padding: 8px 8px 8px 16px;
    color: black;
    background: #F2F4FA;
    border: 2px solid #BFBFBF;
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 2px 1px rgba(0, 0, 0, 0.12), 0px 1px 3px rgba(0, 0, 0, 0.2);
    border-radius: 8px;
}

.course-desc-textarea-container .p-inputtextarea::-webkit-scrollbar {
    /* width */
    width: 8px;
}

.course-desc-textarea-container .p-inputtextarea::-webkit-scrollbar-thumb {
    background-color: #BFBFBF;
    /* color of the scroll thumb */
    border-radius: 20px;
    /* roundness of the scroll thumb */
    border: 9px solid #BFBFBF;
    /* creates padding around scroll thumb */
}

.course-desc-textarea-container .p-inputtext:enabled:hover {
    border-color: #33CC99;
}

.course-desc-textarea-container .p-inputtext:enabled:focus {
    border-color: #33CC99;
}

.course-desc-textarea-container>label {
    font-size: 16px;
    padding-left: 16px;
    color: #BFBFBF;
}

.course-desc-textarea-container .course-desc-counter {
    font-size: 12px;
}

.input-error {
    animation-name: shakeError;
    animation-fill-mode: forwards;
    animation-duration: .6s;
    animation-timing-function: ease-in-out;
}

.input-error .p-inputtext:hover,
.input-error .p-inputtext:enabled:focus,
.input-error .p-inputtext {
    border-color: #E63E3E;
}

.input-error .input-label {
    color: #E63E3E;
}

.error-message:nth-child(2) {
    padding-top: 10px;
}

.error-message {
    color: #E63E3E;
    padding-left: 10px;
}

.create-course-button {
    padding: 8px 32px;
    background: #33CC99;
    border: 1px solid #33CC99;
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 2px 1px rgba(0, 0, 0, 0.12), 0px 1px 3px rgba(0, 0, 0, 0.2);
    border-radius: 50px;
}

.create-course-button:hover {
    background: #00C083;
    border-color: #00C083;
}

.create-course-button:focus {
    box-shadow: none;
    border-color: #33CC99;
}


.group-actions-switch-container {
    display: grid;
    width: 200px;
    margin: 0px 16px 16px;
}

.group-actions-switch {
    margin-left: auto;
    display: flex;
}

.row {
    display: flex;
    align-items: center;
    padding: 4px 0px;
}

/* .column {
    flex-grow: 1;
} */

.column {
    flex-grow: 1;
    display: flex;
    align-items: center;
}
</style>